.txt-exercise {
    border: none;
    border-bottom: 2px solid black; /* Creates the underline */
    background: transparent; /* No background for a clean look */
	font-family: 'Playpen Sans', cursive;
    font-weight: normal;
    outline: none; /* Removes default focus styles */
    font-size: 16px; /* Adjust font size as needed */
    width: 600px; /* Adjust width or use percentage */
    padding: 5px; /* Space above the underline */
    text-align: left; /* Align text if needed */
  }

  .txt-exercise:focus {
    /*border-bottom-color: rgb(19, 130, 117);  Change underline color on focus */
    border-bottom: 3px solid #000;
  }

  .txt-exercise::placeholder {
    color: gray; /* Placeholder text color */
  }
  .checkmark {
    width: 3rem;
    height: 3rem;
  }

  .qna-circle {
    display: flex; align-items: center;
    border: 2px solid rgba(0,0,0,.12);
    border-radius: 50%;
    height: 1.8rem;
    width: 1.8rem;
    margin-right: .3rem;
  }

  .checkmark-circle {
    fill: none;
    stroke: #4caf50;
    stroke-width: 5;
    stroke-dasharray: 314; /* Length of the circle (2 * PI * radius) */
    stroke-dashoffset: 314;
    animation: drawCircle 1s ease-out forwards;
  }

  .checkmark-tick {
    fill: none;
    stroke: #4caf50;
    stroke-width: 5;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 70; /* Length of the tick */
    stroke-dashoffset: 70;
    animation: drawTick 0.5s 1s ease-out forwards; /* Delay start until the circle animation finishes */
  }

  @keyframes drawCircle {
    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes drawTick {
    to {
      stroke-dashoffset: 0;
    }
  }