.report-card-chart-sparkline{
  border: 0 solid #ccc;
  border-top: 0;
  border-right: 0;
  margin-right: 1rem; 
  margin-bottom: 1rem;
}
.report-card-chart-sparkline-title{
  color: #000;
  font-size: .8rem; 
  margin-bottom: 0;
}
.chart-container {
  overflow: visible !important;
}