.atempt-menu{
  display: flex;
  flex-direction: column;
  cursor: pointer;
  font-size: 0.8rem;
  color: rgba(0,0,0,0.7);
  margin-top: 0;
  padding: 3px 15px;
  background-color: #e8eaf6;
}
.atempt-menu:hover{
  background-color: #efefef;
}
.atempt-menu-selected{
  background-color: #bbdefb;
}