.divBody[contentEditable=true]:empty:not(:focus):before {
  content: attr(data-text);
  color:#aaa;
}
.typewriter{
  border-right: .15em solid orange; /* Creates the blinking cursor */
  white-space: wrap; /* Prevents wrapping */
  margin: 0 auto; /* Centers the text */
  letter-spacing: .15em; /* Adds a slight space between characters */
  animation: typewriter 3s steps(40) infinite alternate; /* Animation */
}

@keyframes typewriter {
  from {
    width: 0; /* Starts with no width */
  }
  to {
    width: 100%; /* Ends with full width */
  }
}