.qna-circle {
	display: flex; align-items: center;
	border: 2px solid rgba(0,0,0,.12);
	border-radius: 50%;
	height: 1.8rem;
	width: 1.8rem;
	margin-right: .3rem;
}
.custom-underline2 {
	color: black; /* Font color */
	font-weight: bold;
	background: linear-gradient(to bottom, black 70%, transparent 50%);
	background-size: 100% 2px; /* Adjust thickness of underline */
	background-repeat: repeat;
	box-decoration-break: clone; /* Ensures the background applies to each line */
	padding-bottom: 2px; /* Creates spacing to ensure text doesn't overlap underline */
}
.custom-underline {
	color: black; /* Font color */
	font-family: 'Playpen Sans', cursive;
	font-weight: bold;
	display: inline-block; /* Ensure the underline fits the text width */
	text-decoration: none;
	position: relative;
	padding-left: .5rem;
	padding-right: .5rem;
}

.custom-underline::after {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0; /* Place underline below the text */
	width: 100%;
	height: 2px; /* Thickness of the underline */
	background-color: black; /* Black underline */
	/*z-index: -1;  Keep it behind the text */
}
.answer-text-NO { color: #ef5350; font-weight: bold; text-decoration: line-through; }
.answer-text-YES { color: #1976d2; font-weight: bold; text-decoration: underline;  }